<template>
    <div class="video-slider-collection slider-partial" v-if="data">
        <Flickity ref="flickity" :options="flickityOptions">
            <VideoOverviewItem v-for="item in data" :key="item.id" :data="item"
                :doCalcOverlayPosition="calcOverlayPosition" />
        </Flickity>
    </div>
</template>

<script>
// @import component
import VideoOverviewItem from "@/components/VideoOverviewItem";
import Flickity from "@/components/Flickity";

export default {
    name: "videoSliderBar",
    components: {
        VideoOverviewItem,
        Flickity,
    },
    data() {
        return {
            calcOverlayPosition: false,
            flickityOptions: {
                initialIndex: 0,
                cellAlign: "left",
                contain: true,
                pageDots: false,
                //draggable: false,
                groupCells: true,
                arrowShape:
                    "M50.2,98.9l8.9-8.9L25.4,56.2l73.9,0V43.5l-73.9,0L59.1,9.8l-8.9-8.9l-49,49L50.2,98.9z",
            },
        };
    },
    props: ["data"],
    mounted() {
        this.$refs.flickity.on("dragStart", () => {
            //this.$refs.flickity.$flickity.element.style.pointerEvents = "none";
            this.$refs.flickity.$flickity.element.classList.add("dragging");
        });
        this.$refs.flickity.on("dragEnd", () => {
            // this.$refs.flickity.$flickity.element.style.pointerEvents = "auto";
            this.$refs.flickity.$flickity.element.classList.remove("dragging");
        });
        this.$refs.flickity.on("change", () => {
            this.calcOverlayPosition = true;

            setTimeout(() => {
                this.calcOverlayPosition = false;
            }, 100);
        });
    },
    methods: {
        replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, "g"), replace);
        },
        reformatTitle(title) {
            return title;
        },
    },
};
</script>

<style lang="scss">
// @import styles
@import "./index.scss";

.flickity-slider {
    display: block;
    // display: flex;
}

.flickity-viewport {
    overflow: visible !important;
}

.flickity-cell {
    width: 280px;
    display: inline-block;
    position: absolute !important;
    margin-right: 30px;
}

.video-slider-wrapper .flickity-button:disabled {
    opacity: 0;
}
</style>

<style lang="scss">
// @import styles
// @import "./index.scss";

.video-slider-collection {
    .flickity-slider {
        display: block;
        // display: flex;
    }

    .flickity-viewport {
        overflow: visible !important;
    }

    .video-item {
        width: 393px;
        margin-right: 50px;
        position: absolute;
    }
}

@media only screen and (max-width: 600px) {
    .video-slider-collection .video-item {
        width: 80%;
        margin-right: 20px;
    }
}

// .flickity-cell {
// 	width: 280px;
// 	display: inline-block;
// 	position: absolute !important;
// 	margin-right: 30px;
// }
// .video-slider-wrapper .flickity-button:disabled {
// 	opacity: 0;
// }
</style>
